import axios from "@/axios.js"

export default {
  addItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/cms/book_list", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPaymentDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/payment", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDebtDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/debt", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDebtListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/report_all_debt", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchReportDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/report_by_bill", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDebtByAreaDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/debt_by_area", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAllDebtListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/report_all_debt")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSummaryDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/summary", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListByP32({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/bill", { params: payload })
        .then((response) => {
          // commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListByP32Report({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/daily_report", { params: payload })
        .then((response) => {
          // commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListByP32AreaReport({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/daily_area_report", { params: payload })
        .then((response) => {
          // commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListByP32Print({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/cms/book_list/print", payload)
        .then((response) => {
          // commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchBillWithoutReceiveListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/bill_no_receive", { params: payload })
        .then((response) => {
          commit('SET_BILLS_NO_RECEIVE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListsForMeterPDF({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/book_list/meter", {params: payload})
        .then((response) => {
          // commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListsForPayment({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/mobile/book_list/member", {params: payload})
        .then((response) => {
          // commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItemForPayment({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/book_list/multiple`, item)
        .then((response) => {
          // commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItemForMeter({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/book_list/fix`, item)
        .then((response) => {
          // commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateDateItemForMeter({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/book_list/fix_date/${item.id}`, item)
        .then((response) => {
          // commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createItemForMeter({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/mobile/book_list`, item)
        .then((response) => {
          // commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListsStable({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/cms/book_list/stable`, { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
 getBookListsValidate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/cms/book_list/validate`, { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListsPercent({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/cms/book_list/percent`, { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBookListsWarning({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/cms/book_list/warning`, { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItemNumber({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/book_list/${item._id}`, item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/book_list/${item._id}`, item)
        .then((response) => {
          // commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({commit}, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/cms/book_list/${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDebtByBillAreaDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/debt_report_by_bill_area", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDebtOnlyByBillAreaDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/debt_report_only_by_bill_area", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDebtAllBillDataListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/debt_report_all_bill", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSummaryListItems({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/report/debt_report_summary", { params: payload })
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
