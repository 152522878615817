<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card class="mb-8">
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <h4>บันทึกมิเตอร์</h4>
          </div>
<!--          <div class="vx-col sm:w-1/2 w-full text-right">-->
<!--            <vs-button type="filled" @click="submit()">ดึงข้อมูล</vs-button>-->
<!--          </div>-->
        </div>
        <!--        <div class="vx-row mb-5">-->
        <!--          <div class="vx-col md:w-1/3 sm:w-1/3 xs:w-1/1 w-full">-->
        <!--            <div class="vx-row">-->
        <!--              <div class="vx-col sm:w-1/3 w-full">-->
        <!--                <span>วันที่ชำระ:</span>-->
        <!--              </div>-->
        <!--              <div class="vx-col sm:w-2/3 w-full">-->
        <!--                <vs-input class="w-full" v-model="invoiceData" readonly/>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="vx-row">
          <div class="vx-col sm:w-1/1 w-full">
            <vs-radio v-model="radioBtn"  vs-value="01">
              <span>เลขทะเบียนผู้ใช้น้ำ</span>
            </vs-radio>
            <vs-input v-model="inputNumber"  @keydown="onKeydownUser($event)" style="width: 170px; display: inline-block; margin-left: 10px" :disabled="disable01"/>
          </div>
<!--          <div class="vx-col sm:w-1/3 w-full">-->
<!--            <vs-radio v-model="radioBtn"  vs-value="02">-->
<!--              <span>บ้านเลขที่</span>-->
<!--            </vs-radio>-->
<!--            <vs-input v-model="villageNo" @keydown="onKeydown($event)" style="display: inline-block; margin-left: 10px" :disabled="disable02"/>-->
<!--          </div>-->
<!--          <div class="vx-col sm:w-1/3 w-full">-->
<!--            <vs-radio v-model="radioBtn"  vs-value="03">-->
<!--              <span>เบอร์โทร</span>-->
<!--            </vs-radio>-->
<!--            <vs-input v-model="telNumber" @keydown="onKeydown($event)" style="display: inline-block; margin-left: 10px" :disabled="disable03"/>-->
<!--          </div>-->
        </div>
      </vx-card>
      <vx-card class="mb-8">
        <div class="vx-row mb-5">
<!--          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">-->
<!--            <div class="vx-row">-->
<!--              <div class="vx-col sm:w-1/3 w-full">-->
<!--                <span>รายชื่อ</span>-->
<!--              </div>-->
<!--              <div class="vx-col sm:w-2/3 w-full">-->
<!--                <v-select :options="memberOpt" :clearable="false" @input="setMember" v-model="selectedMember" class="mb-4 md:mb-0">-->
<!--                  <template #no-options="{memberOpt}">-->
<!--                    ไม่พบข้อมูล-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อ - นามสกุล</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.name" readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ที่อยู่</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.address" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>จดมิเตอร์ครั้งแรก</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.meterFirst" readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>จดมิเตอร์ครั้งหลัง</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" :disabled="!isActiveMember" v-model="member.meterLast" ref="inputMeter" type="number" @keydown="onKeydownMeter($event)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-4 pr-1">
          <div class="vx-col sm:w1/1 text-right w-full">
            <MeterNumberReport :org="org" :booklist="bookLists" :member="member"/>
            <vs-button color="primary" @click="submitMeter()">บันทึกมิเตอร์</vs-button>
          </div>
        </div>
      </vx-card>
      <vs-table noDataText="ไม่พบข้อมูล" :data="bookLists" class="table-custom mb-5">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>เดือน</vs-th>
          <vs-th>จดครั้งแรก</vs-th>
          <vs-th>จดครั้งหลัง</vs-th>
          <vs-th>หน่วย</vs-th>
          <vs-th>ค่าน้ำประปา</vs-th>
          <vs-th>ค่าบริการ</vs-th>
          <vs-th>ค่าบำบัดฯ</vs-th>
          <vs-th>ภาษีฯ</vs-th>
          <vs-th>ค่าขยะ</vs-th>
          <vs-th>รวม</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td>
              <p class="product-name font-medium">{{ indextr + 1 }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ tr.CB04  }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.B18  }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.B20  }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.B21 }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ typeOfNumber(tr.BA11) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ typeOfNumber(tr.BA12) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ typeOfNumber(tr.BA14) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ typeOfNumber(tr.BA13) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ typeOfNumber(tr.BA15) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium">{{ sumTable[indextr].toFixed(2) }}</p>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
  import moduleInvoiceDate from "@/store/invoice-date/moduleInvoiceDate.js";
  import moment from "moment";
  import vSelect from 'vue-select';
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import modulePayby from "@/store/payby/modulePayby.js";
  import MeterNumberReport from '../pdf/MeterNumberReport.vue'
  export default {
    components: {
      vSelect,
      MeterNumberReport,
    },
    data() {
      return {
        isActiveMember: false,
        radioBtn: '01',
        date: '',
        inputNumber: '',
        villageNo: '',
        telNumber: '',
        bookLists: [],
        checkBox: [],
        memberOpt: [],
        selectedMember: { label: 'ไม่พบข้อมูล', value: '', index: '', data: '' },
        selectedPayby: { label: '', value: '' },
        member: {
          _id: '',
          name: '',
          meter: '',
          address: '',
          numberIdentify: '',
          telephone: '',
          meterFirst: '',
          meterLast: ''
        },
        sumCheckbox: '',
        sumTable: [],
        userAll: [],
        indexMember: 0,
        sum: '',
        money: '',
        change: '',
        typePayment: '',
        disable01: false,
        disable02: true,
        disable03: true,
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        payBySelect: [],
      }
    },
    watch: {
      radioBtn: {
        handler (val) {
          if (val === '01') {
            this.disable01 = false;
            this.disable02 = true;
            this.disable03 = true;
            this.villageNo = '';
            this.telNumber = '';
          }
          if (val === '02') {
            this.disable01 = true;
            this.disable02 = false;
            this.disable03 = true;
            this.inputNumber = '';
            this.telNumber = '';
          }
          if (val === '03') {
            this.disable01 = true;
            this.disable02 = true;
            this.disable03 = false;
            this.inputNumber = '';
            this.villageNo = '';
          }
        },
        deep: true
      },
    },
    computed: {
      org() {
        return this.$store.state.config.item;
      },
      // paybys() {
      //   let options = [];
      //   this.$store.state.payby.items.forEach(function (item) {
      //     options.push({
      //       'label': item.pb02,
      //       'value': item.pb01
      //     });
      //   });
      //   return options;
      // },
      // invoiceData() {
      //   const date = this.$store.state.invoiceDate.items[0];
      //   const formatDate = moment(date).format('DD/MM/YYYY')
      //   return formatDate;
      // }
    },
    methods: {
      // async init() {
      //   this.selectedPayby = {
      //     'label': await this.$store.state.payby.items[0].pb02,
      //     'value': await this.$store.state.payby.items[0].pb01
      //   };
      // },
      onKeydownUser(e){
        if(e.keyCode == 13 || e.which == 13) { //Enter keycode
          this.submit()
        }
      },
      typeOfNumber(value) {
        const data = parseFloat(value).toFixed(2)
        return data;
      },
      splitDate(value, type) {
        const date = value.split('-');
        if (type === 'month') {
          return date[0];
        } else {
          return date[1];
        }
      },
      setMember(item) {
        this.indexMember = item.index;
        this.setUserData(this.userAll, item.index);
      },
      calculateBA(index) {
        if (this.checkBox[index]) {
          this.sumCheckbox += this.sumTable[index];
        } else {
          this.sumCheckbox -= this.sumTable[index];
        }
      },
      onKeydownMeter(e){
        if(e.keyCode == 13 || e.which == 13) { //Enter keycode
          this.submitMeter()
        }
      },
      submit() {
        this.$vs.loading();
        if (this.radioBtn === '01') {
          const user = { M01: this.inputNumber  };
          this.getUser(user);
        } else if (this.radioBtn === '02') {
          const user = { M05: this.villageNo };
          const bookLists = { B03: this.inputNumber, B10: '0', sortBy: 'B01:asc' };
          this.getUser(user);
        } else {
          const user = { M07: this.telNumber };
          const bookLists = { B03: this.inputNumber, B10: '0', sortBy: 'B01:asc' };
          this.getUser(user);
        }
      },
      async submitMeter() {
        if (this.member.meterLast === '' || parseInt(this.member.meterLast) < parseInt(this.member.meterFirst)) {
          const text = 'กรุณากรอกตัวเลขลงช่องจดมืเตอร์ครั้งหลัง';
          this.warningDialog(text);
          return;
        }
        if (this.member._id === '') {
          const text = 'ไม่มีผู้ใช้น้ำที่คุณเลือก';
          this.warningDialog(text);
          return;
        }
        this.$vs.loading();
        const user = JSON.parse(sessionStorage.getItem('vuex'));
        const staffId = user.owner.user.user._id;
        const body = {
          member_id: this.member._id,
          staff_id: staffId,
          current_meter: parseInt(this.member.meterLast),
          last_meter: parseInt(this.member.meterFirst),
          B10: '0'
        };
        await this.$store.dispatch("bookLists/updateItemForMeter", body);
        this.$vs.loading.close();
        this.successDialog();
      },
      setUserData(member, index) {
        // let type = '';
        // if (member[index].M17 !== undefined && member[index].M17 !== null && member[index].M17 !== '') {
        //   type = this.payBySelect[parseInt(member[index].M17)];
        // }
        this.member = {
          _id: member[index]._id,
          member_id: member[index].M01,
          area: member[index].M02,
          name: member[index].M04,
          meter: member[index].M27,
          address: member[index].M05+ ' ' + member[index].soi+ ' ' + member[index].road+ ' '+ member[index].M06,
          numberIdentify: member[index].M08,
          telephone:  member[index].M07,
          meterFirst: member[index].M09,
          meterLast: ''
        };
        let inputEl = this.$refs['inputMeter'].$el.querySelector('input')
        inputEl.focus()
        this.$vs.loading.close();
        const bookLists = { B03: member[index].M01, sortBy: 'B01:asc' };
        this.getBookLists(bookLists);
        if(member[index].M23 === '01'){
          this.isActiveMember = true
        }else{
          this.isActiveMember = false
          this.inActiveUserDialog()
        }
      },
      async getUser(payload) {
        await this.$store.dispatch("member/fetchDataListByFilter", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data;
            this.userAll = res.data;
            let options = [];
            for (let i = 0; i < member.length; i++) {
              options.push({
                'label': member[i].M04,
                'value': member[i].M01,
                'index': i,
                'data': member[i]
              });
            }
            this.memberOpt = options;
            this.selectedMember = {
              label: options[0].label,
              value:  options[0].value,
              data:  options[0].data
            };
            this.setUserData(member, 0);
          } else {
            this.noDataMember();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.noDataMember();
          this.$vs.loading.close();
        });
      },
      async getBookLists(payload) {
        await this.$store.dispatch("bookLists/getBookListsForPayment", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const book = res.data;
            this.bookLists = res.data;
            let arr = [];
            let sumRead = 0;
            let box = [];
            for (let i = 0; i < book.length; i++) {
              let BA11 = 0;
              let BA12 = 0;
              let BA13 = 0;
              let BA14 = 0;
              let BA15 = 0;
              if (book[i].BA11 !== undefined && book[i].BA11 !== null && book[i].BA11 !== '') {
                BA11 = parseFloat(book[i].BA11);
              }
              if (book[i].BA12 !== undefined && book[i].BA12 !== null && book[i].BA12 !== '') {
                BA12 = parseFloat(book[i].BA12);
              }
              if (book[i].BA13 !== undefined && book[i].BA13 !== null && book[i].BA13 !== '') {
                BA13 = parseFloat(book[i].BA13);
              }
              if (book[i].BA14 !== undefined && book[i].BA14 !== null && book[i].BA14 !== '') {
                BA14 = parseFloat(book[i].BA14);
              }
              if (book[i].BA15 !== undefined && book[i].BA15 !== null && book[i].BA15 !== '') {
                BA15 = parseFloat(book[i].BA15);
              }
              const sum = BA11 + BA12 + BA13 + BA14 + BA15;
              sumRead += sum;
              arr.push(sum);
              box[i] = true;
            }
            this.checkBox = box;
            this.sumCheckbox = sumRead.toFixed(2);
            this.sumTable = arr;
          } else {
            this.noDataBookLists();
          }
          this.$vs.loading.close();
        }).catch((error) => {
          this.noDataBookLists();
          this.$vs.loading.close();
        });
      },
      noDataMember() {
        this.memberOpt = [];
        this.selectedMember = {
          label: 'ไม่พบข้อมูล',
          value: '',
          data: ''
        };
        this.member = {
          _id: '',
          member_id: '',
          area: '',
          name: '',
          meter: '',
          address: '',
          numberIdentify: '',
          telephone:  '',
          meterFirst: '',
          meterLast: ''
        };
      },
      noDataBookLists() {
        this.bookLists = [];
        this.sumTable = [];
        this.sum = '';
        this.money = '';
        this.change = '';
        this.typePayment = '';
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      route(){
        this.$router.push('/user').catch(() => {})
      },
      inActiveUserDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: 'รายนี้ยกเลิกใช้น้ำแล้ว',
          acceptText: 'ปิด'
        })
      },
      failDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: 'จดมิเตอร์ไม่สำเร็จ',
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'จดมิเตอร์สำเร็จ',
          acceptText: 'ปิด',
        })
      }
    },
    async created() {
      // this.$vs.loading();
      // if (!moduleInvoiceDate.isRegistered) {
      //   this.$store.registerModule('invoiceDate', moduleInvoiceDate)
      //   moduleInvoiceDate.isRegistered = true
      // }
      if(!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
      // if (!modulePayby.isRegistered) {
      //   this.$store.registerModule('payby', modulePayby);
      //   modulePayby.isRegistered = true
      // }
      // await this.$store.dispatch("payby/fetchDataListItems");
      // await this.$store.dispatch("invoiceDate/fetchDataListItems");
      // this.$vs.loading.close();
      // this.init();
    }
  }

</script>

<style lang="scss">
  .table-custom .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    background: #8794a2;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
  }
</style>
