import state from './moduleInvoiceDateState';
import mutations from './moduleInvoiceDateMutations';
import actions from './moduleInvoiceDateActions';
import getters from './moduleInvoiceDateGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
