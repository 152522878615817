import axios from "@/axios.js"

export default {
  addItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/cms/member", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListByFilter({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member", {params: payload})
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchMemberListByFilter({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member/search", {params: payload})
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItemsByArea({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member/area", {params: payload})
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItemsReport({commit}, status) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member", {params: {M23: status}})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItemsIdentify({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member", {params: payload})
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMemberForReport({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/member/debt", {params: payload})
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataItem({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/cms/member/${id}`)
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/member/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({commit}, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/cms/member/${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
