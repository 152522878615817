export default {
  ADD_ITEM(state, item) {
    state.items.unshift(item)
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  REMOVE_ITEM(state, itemId) {
    const userIndex = state.items.findIndex((u) => u.id == itemId)
    state.items.splice(userIndex, 1)
  },
  UPDATE_ITEM(state, item) {
    const itemIndex = state.items.findIndex((t) => t.id == item.id)
    Object.assign(state.items[itemIndex], item)
  }
}
