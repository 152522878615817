<template>
  <vs-button @click="generatePDF()" color="primary" type="border" class="mr-4 mb-4 md:mb-0">พิมพ์รายงาน</vs-button>
</template>

<script>

  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import moment from "moment";

  export default {
    props: ['booklist','member', 'org'],
    components: {
      jsPDF,
    },
    name: "saveMeterNumber",
    data() {
      return {
        year: '',
        date: ''
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในใบบันทึกเลขมาตร',
          acceptText: 'ปิด'
        })
      },
      formatTextDate(text){
        try {
          let dateArr0 = text.split('/');
          let day = dateArr0[0];
          let month = dateArr0[1];
          let year = 0;
          if(dateArr0[2].length === 2){
            year = parseInt(dateArr0[2]) + 2543;
          }else{
            year = parseInt(dateArr0[2]) + 543;
          }
          return day + '/' + month + '/' + year
        }catch (e) {
          return text
        }
      },
      generatePDF() {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const orgData = this.org;
        const year = this.year;
        const date = this.currentThaiDate();
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'normal');
        doc.setFontSize(14);
        const member = this.member
        const bookLists = this.booklist
        for(let i = 0; i < 1; i++) {
          doc.autoTable({
            styles: { font: 'THSarabunNew', fontSize: 14, cellPadding: {top: 0.65, bottom: 0.65 } },
            margin: { left: 10, top: 20, right: 10 },
            theme: 'plain',
            columnStyles: {
              0: {cellWidth: 20},
              1: {cellWidth: 25},
              2: {cellWidth: 15},
              3: {cellWidth: 25},
              4: {cellWidth: 50},
              5: {cellWidth: 10},
              6: {cellWidth: 40},
            },
            body: [
              [
                { content: 'ผู้ใช้น้ำ', styles: {fontStyle: 'bold'} },
                member.member_id,
                { content: member.name, colSpan: 6 }
              ],
              [
                ' ',
                { content: member.address, colSpan: 7 }
              ],
              [
                { content: 'เลขที่มิเตอร์', styles: {fontStyle: 'bold'} },
                { content: member.meter, colSpan: 2 },
                { content: '', styles: {fontStyle: 'bold'} },
                member.M09,
                { content: 'พื้นที่', styles: {fontStyle: 'bold'} },
                member.area
              ]
            ],
            didDrawPage: function (data) {
              // Header
              doc.setFontSize(18);
              doc.text(orgData.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
              doc.text(orgData.Org03, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
              doc.setFontSize(16);
              // Footer
              // doc.setFont('THSarabunNew', 'normal');
              // doc.setFontSize(14);
              // var str = 'Page ' + doc.internal.getNumberOfPages();
              // if (typeof doc.putTotalPages === 'function') {
              //   str = str + ' of ' + totalPagesExp
              // }
              // doc.setFontSize(12);
              // var pageSize = doc.internal.pageSize;
              // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // doc.text(moment().format('DD/MM/YYYY'), 10, pageHeight - 10,)
              // doc.text(str, 230, pageHeight - 10, {align: 'right'});
            },
          });
          let array = [];
          let firstDate;
          let oldFirstDate;
          for(let j = 0; j < bookLists.length; j++){
            const billArr = bookLists[j];
            let date = moment(bookLists.B08).format('DD/MM/YYYY');
            let lastDate;
            if(date === 'Invalid date') {
              let dateArr = bookLists.B08.split(' ');
              let dateArr0 = dateArr[0].split('/');
              let yearth = parseInt(dateArr0[2]) + 543;
              lastDate = dateArr0[0] + '/' + dateArr0[1] + '/' + yearth;
            } else {
              let dateArr0 = date.split('/');
              let yearth = parseInt(dateArr0[2]) + 543;
              lastDate = dateArr0[0] + '/' + dateArr0[1] + '/' + yearth;
            }
            if(j === 0) {
              firstDate = lastDate;
              oldFirstDate = lastDate;
            } else {
              firstDate = oldFirstDate;
              oldFirstDate = lastDate;
            }
            const dateB17 = this.formatTextDate(billArr.B17);
            const dateB19 = this.formatTextDate(billArr.B19);
            array.push([j + 1, year, billArr.B01, billArr.CB04, dateB17, billArr.B18, dateB19, billArr.B20, billArr.B21])
          }
          doc.autoTable({
            styles: { font: 'THSarabunNew', fontSize: 14 },
            margin: { left: 10, top: 20, right: 10 },
            theme: 'plain',
            head: [['ลำดับ', 'ปีงบ', 'รหัสรอบ', 'ชื่อรอบ/ประจำเดือน', 'จดครั้งก่อน', 'เลขจดก่อน', 'จดครั้งหลัง', 'เลขจดหลัง', 'หน่วยที่ใช้']],
            body: array
          });
        }
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        let nameDate = moment().format('DD-MM-YYYY');
        let date0 = nameDate.split('-');
        let nameYear = parseInt(date0[2]) + 543;
        let formDate = date0[0] + '-' + date0[1] + '-' + nameYear;
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1 ; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save(`ใบบันทึกเลขมาตร` + formDate + '.pdf');
      }
    },
    async created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>

<style scoped>
</style>
